import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'gstr1ReturnSummary',
  props: ['searchParams'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.gstGstr1ReturnSummary(this.searchParams);
      } else {
        this.userData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.gstGstr1ReturnSummary(this.searchParams);
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      gstr1ReturnSummaryData: [],
      gstr1ReturnSummaryFields: [
        // {
        //     key: 'chksum',
        //     label: 'Invoice Check sum value'
        // },
        // {
        //     key: 'period_month_year',
        //     label: 'Period Name'
        // },
        {
          key: 'sec_nm',
          label: 'Return Section',
          class: 'text-center'
        },
        {
          key: 'ttl_rec',
          label: 'Total Record Count',
          class: 'text-center'
        },
        {
          key: 'ttl_tax',
          label: 'Total records value',
          class: 'text-center'
        },
        {
          key: 'ttl_igst',
          label: 'Total IGST',
          class: 'text-center'
        },
        {
          key: 'ttl_cgst',
          label: 'Total CGST',
          class: 'text-center'
        },
        {
          key: 'ttl_sgst',
          label: 'Total SGST',
          class: 'text-center'
        },
        {
          key: 'ttl_cess',
          label: 'Total Cess',
          class: 'text-center'
        },
        {
          key: 'ttl_val',
          label: 'Total taxable value of records',
          class: 'text-center'
        }
      ]
    };
  },
  mounted() {
    if (this.searchParams.legalEntity) {
      this.gstGstr1ReturnSummary(this.searchParams);
    }
  },
  methods: {
    formattedAmount(num) {
      // this function formats the amount that is wrapped inside this function into Indian Currency System with rupee symbol
      return commonHelper.formatCurrencyAmount(num);
    },
    gstGstr1ReturnSummary(params) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        le_id: params.legalEntity.value,
        gst_no: params.gstNumber.value,
        period: params.month.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/gstr1ReturnSummary', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.gstr1ReturnSummaryData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
