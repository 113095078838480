import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';

export default {
  name: 'docSummary',
  props: ['searchParams', 'editMode'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.docSummary(this.searchParams);
        this.docSummaryAsPerGov(this.searchParams);
      } else {
        this.docSeqAsPrBooksData = [];
        this.docSeqAsPrGovData = [];
      }
    },
    editMode() {
      this.edit = this.editMode;
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    // updateEdit() {
    //   this.$emit('edit', this.edit);
    // },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.docSummary(this.searchParams);
    },
    perPage: function() {
      this.currentPage = 1;
      this.docSummary(this.searchParams);
    },
    currentPage1: function() {
      this.searchParams._page = this.currentPage1 - 1;
      this.docSummaryAsPerGov(this.searchParams);
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.docSummaryAsPerGov(this.searchParams);
    }
  },
  data() {
    return {
      edit: false,
      loading: false,
      currentPage: 1,
      currentPage1: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      perPage1: commonHelper.perPageRecord,
      pageOptions1: commonHelper.getPageOption(),
      totalRows1: null,
      hdrId: null,
      docSeqAsPrBooksData: [
        // {
        //   document_issue_id: 0,
        //   serial_number: '',
        //   from_serial_no: '',
        //   to_serial_no: '',
        //   total_number: '',
        //   cancelled: 0,
        //   net_issued: '',
        //   reco_status: '',
        //   hdr_id_sales: this.hdrId,
        //   sl: 1
        // }
      ],
      docSeqAsPrBooksFields: [
        {
          key: 'selectbox',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        { key: 'sl', label: 'Sl. No.', class: 'text-center' },
        { key: 'add', stickyColumn: true, class: 'text-center' },
        { key: 'remove', stickyColumn: true, class: 'text-center' },
        {
          key: 'serial_id',
          label: 'Document Number',
          class: 'text-center'
        },
        {
          key: 'serial_number',
          label: 'Document Type',
          class: 'text-center '
        },
        {
          key: 'from_serial_no',
          label: 'From Doc. Number',
          class: 'text-center'
        },
        {
          key: 'to_serial_no',
          label: 'To Doc. Number',
          class: 'text-center'
        },
        {
          key: 'total_number',
          label: 'Total Number',
          class: 'text-center'
        },
        {
          key: 'cancelled',
          label: 'Cancelled',
          class: 'text-center'
        },
        {
          key: 'net_issued',
          label: 'Net Issued',
          class: 'text-center'
        },
        {
          key: 'reco_status',
          label: 'Reco Status',
          class: 'text-center'
        }
      ],
      docSeqAsPrGovFields: [
        {
          key: 'doc_num',
          label: 'Document Number',
          class: 'text-center '
        },
        {
          key: 'setup_value',
          label: 'Doc Value'
        },
        {
          key: 'docs_from',
          label: 'From Doc. Number',
          class: 'text-center'
        },
        {
          key: 'docs_to',
          label: 'To Doc. Number',
          class: 'text-center'
        },
        {
          key: 'docs_totnum',
          label: 'Total Number',
          class: 'text-center'
        },
        {
          key: 'docs_cancel',
          label: 'Cancelled',
          class: 'text-center'
        },
        {
          key: 'net_issue',
          label: 'Net Issued',
          class: 'text-center'
        }
      ],
      docSeqAsPrGovData: [],
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      requestId: '',
      showValueSetModal: false,
      specIndex: null,
      unsubscribe: null,
      selectAllCheckBox: false,
      documentIssueId: null
    };
  },
  mounted() {
    if (this.searchParams.legalEntity) {
      this.docSummary(this.searchParams);
      this.docSummaryAsPerGov(this.searchParams);
    }
    // if (this.searchParams) {
    //   this.docSummaryAsPerGov(this.searchParams);
    // }
    // this.unsubscribe = this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'shared/setActionName') {
    //     const actionName = state.shared.actionName;
    //     if (actionName === 'update' || actionName === 'edit') {
    //       this.editMode = !this.editMode;
    //     }
    //   }
    // });
  },
  methods: {
    docSummary(params) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: params.legalEntity.value,
        gst_no: params.gstNumber.value,
        period: params.month.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/getDocSeqAsPerBooksData', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            if (result.length) {
              this.docSeqAsPrBooksData = result;
              this.hdrId = this.docSeqAsPrBooksData[0].hdr_id_sales;
              this.docSeqAsPrBooksData.map((ele, index) => {
                ele.sl = index + 1;
              });
              this.totalRows = resp.data.data.total_elements;
            } else {
              this.docSeqAsPrBooksData = [];
            }
            this.getDocSeqHdrId(params);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    addEditDocSeqData() {
      let arr = [];
      for (let i = 0; i < this.docSeqAsPrBooksData.length; i++) {
        if (
          !this.docSeqAsPrBooksData[i].serial_number ||
          !this.docSeqAsPrBooksData[i].from_serial_no ||
          !this.docSeqAsPrBooksData[i].to_serial_no ||
          !this.docSeqAsPrBooksData[i].total_number
        ) {
          arr.push(i + 1);
        }
      }
      if (arr.length) {
        this.validateMandatoryFields(arr);
      } else {
        const docSequence = this.docSeqAsPrBooksData.map(key => {
          return {
            document_issue_id: key.document_issue_id
              ? key.document_issue_id
              : 0,
            hdr_id_sales: key.hdr_id_sales,
            serial_number: key.serial_number,
            from_serial_no: key.from_serial_no,
            to_serial_no: key.to_serial_no,
            total_number: key.total_number,
            cancelled: key.cancelled,
            net_issued: key.net_issued,
            reco_status: key.reco_status
          };
        });
        const payload = {
          data: docSequence
        };
        this.loading = true;
        this.$store
          .dispatch('itcDataUpdation/addEditDocSeq', payload)
          .then(response => {
            this.loading = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.edit = false;
              this.eventBus.$emit('edit', false);
              this.docSummary(this.searchParams);
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loading = false;
            this.showAlert = true;
            this.isSuccess = false;
          });
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
      }
    },
    validateMandatoryFields(arr) {
      let message =
        arr.length > 1
          ? 'Please fill all the mandatory fields for line nos -'
          : 'Please fill all the mandatory fields for line no. -';

      for (let i = 0; i < arr.length; i++) {
        if (i != arr.length - 1) {
          message += ' ' + arr[i] + ',';
        } else {
          message += ' ' + arr[i];
        }
      }
      alert(message);
    },
    docSummaryAsPerGov(params) {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        le_id: params.legalEntity.value,
        gst_no: params.gstNumber.value,
        period: params.month.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/getDocSeqAsPerGovData', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.docSeqAsPrGovData = result;
            this.totalRows1 = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addNewRow() {
      if (this.edit && this.hdrId) {
        this.docSeqAsPrBooksData.push({
          document_issue_id: 0,
          serial_number: '',
          from_serial_no: '',
          to_serial_no: '',
          total_number: '',
          cancelled: 0,
          net_issued: '',
          reco_status: '',
          hdr_id_sales: this.hdrId,
          sl: this.docSeqAsPrBooksData.length + 1
        });
      }
    },
    removeRow(index) {
      if (this.edit) {
        this.docSeqAsPrBooksData.splice(index, 1);
      }
      this.docSeqAsPrBooksData.map((ele, index) => {
        ele.sl = index + 1;
      });
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    changeData(index, item) {
      this.docSeqAsPrBooksData[index].net_issued = null;
      this.docSeqAsPrBooksData[index].cancelled = 0;
      this.calCulateNetIssued(index, item);
    },
    calCulateNetIssued(index, item) {
      if (item.total_number) {
        if (+item.total_number >= +item.cancelled) {
          this.docSeqAsPrBooksData[index].net_issued =
            this.docSeqAsPrBooksData[index].total_number -
            this.docSeqAsPrBooksData[index].cancelled;
          this.docSeqAsPrBooksData = JSON.parse(
            JSON.stringify(this.docSeqAsPrBooksData)
          );
        } else {
          this.docSeqAsPrBooksData[index].cancelled = 0;
          this.docSeqAsPrBooksData[index].net_issued =
            this.docSeqAsPrBooksData[index].total_number -
            this.docSeqAsPrBooksData[index].cancelled;
          alert('Cancelled value can not be more than the Total No.');
        }
      } else {
        this.docSeqAsPrBooksData[index].cancelled = 0;
        alert('Please fill the Total Number field first');
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.GSTR1_DOCUMENT_NATURE) {
        this.docSeqAsPrBooksData[this.specIndex].serial_number =
          item.value_code;
      }
      this.docSeqAsPrBooksData = JSON.parse(
        JSON.stringify(this.docSeqAsPrBooksData)
      );
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'gstDocNature') {
        this.docSeqAsPrBooksData[index].serial_number = null;
      }
    },
    selectAllBoxChecked(flag) {
      if (flag) {
        this.docSeqAsPrBooksData = this.docSeqAsPrBooksData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
      } else {
        this.docSeqAsPrBooksData = this.docSeqAsPrBooksData.map(
          (data, index) => {
            data.selectBox = this.selectAllCheckBox;
            if (this.selectAllCheckBox) {
              this.selectBoxChecked(data.selectBox, index);
            }
            return data;
          }
        );
      }
    },
    selectBoxChecked(flag, index, item, docIssueId) {
      this.docSeqAsPrBooksData[index].selectBox = flag;
      if (flag === true) {
        this.documentIssueId = item.document_issue_id;
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.document_issue_id !== docIssueId
        );
      }
    },
    deleteDocSeqData() {
      const payload = {
        document_issue_id: this.documentIssueId
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/deleteDocumentSequence', payload)
        .then(resp => {
          this.loading = false;
          this.showAlert = true;
            if (resp.status === 200) {
              this.isSuccess = true;
              this.responseMsg = resp.data.message;
              this.edit = false;
            } else {
              this.isSuccess = false;
              this.responseMsg = resp.data.message;
            }
        })
        .catch(() => {
          this.loading = false;
        });       
        this.docSummary(this.searchParams);
    }
  },
  // beforeDestroy() {
  //   this.unsubscribe();
  // }
};
