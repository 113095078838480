import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import salesRegister from '../../salesRegister';
import difference from './difference';
import total from '../hsnSummary/total';

export default {
  name: 'gstr1SummaryForm',
  components: {
    salesRegister,
    difference,
    total
  },
  props: ['searchParams'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.gstGstr1Summary(this.searchParams);
      } else {
        this.gstR1SummaryData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.gstGstr1Summary(this.searchParams);
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      expandBookHead: false,
      expandGstrHead: false,
      expandDiffHead: false,
      perPage: 100,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      gstR1SummaryData: [],
      gstR1SummaryField: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'col-checkbox'
        },
        {
          key: 'CATEGORIES',
          label: 'Type',
          class: 'text-center col-fix'
        },
        {
          key: 'BOOKS_TOTAL_INV',
          label: 'Count Invoice Number',
          class: 'text-center col-small'
        },
        {
          key: 'BOOK_INVOICE_VALUE',
          label: 'Books Invoice Amount',
          class: 'text-center col-small'
        },
        {
          key: 'BOOK_TAXABLE_VALUE',
          label: 'Books Taxable Amount',
          class: 'text-center col-small'
        },
        {
          key: 'BOOKS_tax_AMT',
          label: 'Total Tax Amt',
          variant: 'success',
          class: 'text-center col-small'
        },
        {
          key: 'BOOKS_IGST',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-book-amt col-small'
        },
        {
          key: 'BOOKS_CGST',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-book-amt col-small'
        },
        {
          key: 'BOOKS_SGST',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-book-amt col-small'
        },
        {
          key: 'GSTR1_INVOICES',
          label: 'Count Invoice Number',
          class: 'text-center col-small'
        },
        {
          key: 'GSTR1_INVOICE_VALUE',
          label: 'GSTR1 Invoice Amount',
          class: 'text-center col-small'
        },
        {
          key: 'GSTR1_TAXABLE_VALUE',
          label: 'GSTR1 Taxable Amount',
          class: 'text-center col-small'
        },
        {
          key: 'GSTR1_TOTAL_tax_AMT',
          label: 'Total Tax Amt',
          variant: 'success',
          class: 'text-center col-small'
        },
        {
          key: 'GSTR1_IGST_AMT',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-gstr1-amt col-small'
        },

        {
          key: 'GSTR1_CSGT_AMT',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-gstr1-amt col-small'
        },
        {
          key: 'GSTR1_SGST_AMT',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-gstr1-amt col-small'
        },
        {
          key: 'DIFF_INV_DIFF',
          label: 'Count Invoice Number',
          class: 'text-center col-small'
        },
        {
          key: 'DIFF_INVOICE_VALUE',
          label: 'Difference Invoice Amount',
          class: 'text-center col-small'
        },
        {
          key: 'DIFF_TAXABLE_VALUE',
          label: 'Difference Taxable Amount',
          class: 'text-center col-small'
        },
        {
          key: 'DIFF_INV_AMT',
          label: 'Total Tax Amt',
          variant: 'success',
          class: 'text-center col-small'
        },
        {
          key: 'DIFF_IGST',
          label: 'IGST',
          variant: 'success',
          class: 'text-center d-total-diff-amt col-small'
        },
        {
          key: 'DIFF_CGST',
          label: 'CGST',
          variant: 'success',
          class: 'text-center d-total-diff-amt col-small'
        },
        {
          key: 'DIFF_SGST',
          label: 'SGST',
          variant: 'success',
          class: 'text-center d-total-diff-amt col-small'
        },
        {
          key: 'difference',
          label: 'Difference',
          class: 'text-center col-small'
        }
      ],
      selectedRow: [],
      selectAllCheckBox: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      requestId: '',
      minimizeFlag: false,
      showOpenCategoriesModal: false,
      showOpenDifferenceModal: false,
      searchFilters: {},
      totalAmountBooks: 0,
      showOpenGetTotalModal: false,
      gstr1SummaryTotalData: []
    };
  },
  mounted() {
    if (this.searchParams.legalEntity) {
      this.gstGstr1Summary(this.searchParams);
    }
  },
  methods: {
    formattedAmount(num) {
      // this function formats the amount that is wrapped inside this function into Indian Currency System with rupee symbol
      return commonHelper.formatCurrencyAmount(num);
    },
    openGetTotalModal() {
      this.showOpenGetTotalModal = true;
    },
    hideGetTotalModal(flag) {
      this.showOpenGetTotalModal = flag;
    },
    gstGstr1Summary(params) {
      this.gstr1SummaryTotalData = [];
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        le_id: params.legalEntity.value,
        gstn_no: params.gstNumber.value,
        period: params.month.value
      };
      this.loading = true;
      let booksInvAmt = 0;
      let booksTaxableAmt = 0;
      let booksAmt = 0;
      let booksAmtIgst = 0;
      let booksAmtCgst = 0;
      let booksAmtSgst = 0;
      let gstr1InvAmt = 0;
      let gstr1TaxableAmt = 0;
      let gstr1Amt = 0;
      let gstr1AmtIgst = 0;
      let gstr1AmtCgst = 0;
      let gstr1AmtSgst = 0;
      let diffTaxableAmt = 0;
      let diffInvAmt = 0;
      let diffAmt = 0;
      let diffAmtIgst = 0;
      let diffAmtCgst = 0;
      let diffAmtSgst = 0;
      this.$store
        .dispatch('itcDataUpdation/getGstr1Summary', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            let count = 0;
            for (let i = 0; i < result.length; i++) {
              if (result[i].CATEGORIES === 'DOCISS') {
                result.splice(i, 1);
                count++;
              }
            }
            this.gstR1SummaryData = result;
            this.totalRows = resp.data.data.total_elements - count;
            let totalamountbooks = 0;
            for (let i of result) {
              totalamountbooks = totalamountbooks + +i.BOOKS_tax_AMT;
            }
            this.totalAmountBooks = totalamountbooks;
            for (let i = 0; i < result.length; i++) {
              if (result[i].CATEGORIES != 'NOT APPLICABLE') {
                booksInvAmt = booksInvAmt + +result[i].BOOK_INVOICE_VALUE;
                booksTaxableAmt =
                  booksTaxableAmt + +result[i].BOOK_TAXABLE_VALUE;
                booksAmt = booksAmt + +result[i].BOOKS_tax_AMT;
                booksAmtIgst = booksAmtIgst + +result[i].BOOKS_IGST;
                booksAmtCgst = booksAmtCgst + +result[i].BOOKS_CGST;
                booksAmtSgst = booksAmtSgst + +result[i].BOOKS_SGST;
                gstr1InvAmt = gstr1InvAmt + +result[i].GSTR1_INVOICE_VALUE;
                gstr1TaxableAmt =
                  gstr1TaxableAmt + +result[i].GSTR1_TAXABLE_VALUE;
                gstr1Amt = gstr1Amt + +result[i].GSTR1_TOTAL_tax_AMT;
                gstr1AmtIgst = gstr1AmtIgst + +result[i].GSTR1_IGST_AMT;
                gstr1AmtCgst = gstr1AmtCgst + +result[i].GSTR1_CSGT_AMT;
                gstr1AmtSgst = gstr1AmtSgst + +result[i].GSTR1_SGST_AMT;
                diffInvAmt = diffInvAmt + +result[i].DIFF_INVOICE_VALUE;
                diffTaxableAmt = diffTaxableAmt + +result[i].DIFF_TAXABLE_VALUE;
                diffAmt = diffAmt + +result[i].DIFF_INV_AMT;
                diffAmtIgst = diffAmtIgst + +result[i].DIFF_IGST;
                diffAmtCgst = diffAmtCgst + +result[i].DIFF_CGST;
                diffAmtSgst = diffAmtSgst + +result[i].DIFF_SGST;
              }
            }
            this.gstr1SummaryTotalData.push({
              book_invoice_value: booksInvAmt.toFixed(2),
              book_taxable_value: booksTaxableAmt.toFixed(2),
              book_tax_amount: booksAmt.toFixed(2),
              books_igst: booksAmtIgst.toFixed(2),
              books_cgst: booksAmtCgst.toFixed(2),
              books_sgst: booksAmtSgst.toFixed(2),
              gstr1_invoice_value: gstr1InvAmt.toFixed(2),
              gstr1_taxable_value: gstr1TaxableAmt.toFixed(2),
              gstr1_total_tax_amount: gstr1Amt.toFixed(2),
              gstr1_igst: gstr1AmtIgst.toFixed(2),
              gstr1_cgst: gstr1AmtCgst.toFixed(2),
              gstr1_sgst: gstr1AmtSgst.toFixed(2),
              diff_invoice_value: diffInvAmt,
              diff_taxable_value: diffTaxableAmt,
              diff_total_tax_amount: diffAmt,
              diff_igst: diffAmtIgst,
              diff_cgst: diffAmtCgst,
              diff_sgst: diffAmtSgst
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    expandColumn(data, class1, class2) {
      let elem = document.querySelectorAll(`.${class1}`);
      if (elem && elem[0] && elem[0].classList.contains(class1)) {
        if (data === 'BOOKS_tax_AMT') {
          this.expandBookHead = true;
          elem.forEach(el => {
            el.classList.replace('d-total-book-amt', class2);
          });
        } else if (data === 'GSTR1_TOTAL_tax_AMT') {
          this.expandGstrHead = true;
          elem.forEach(el => {
            el.classList.replace('d-total-gstr1-amt', class2);
          });
        } else if (data === 'DIFF_INV_AMT') {
          this.expandDiffHead = true;
          elem.forEach(el => {
            el.classList.replace('d-total-diff-amt', class2);
          });
        }
      } else {
        if (data === 'BOOKS_tax_AMT') {
          this.expandBookHead = false;
          let otherElem = document.querySelectorAll('.other-total-book-amt');
          otherElem.forEach(el => {
            el.classList.replace('other-total-book-amt', 'd-total-book-amt');
          });
        } else if (data === 'GSTR1_TOTAL_tax_AMT') {
          this.expandGstrHead = false;
          let otherElem = document.querySelectorAll('.other-total-gstr1-amt');
          otherElem.forEach(el => {
            el.classList.replace('other-total-gstr1-amt', 'd-total-gstr1-amt');
          });
        } else if (data === 'DIFF_INV_AMT') {
          this.expandDiffHead = false;
          let otherElem = document.querySelectorAll('.other-total-diff-amt');
          otherElem.forEach(el => {
            el.classList.replace('other-total-diff-amt', 'd-total-diff-amt');
          });
        }
      }
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    selectBoxChecked(flag, index, item, category) {
      this.gstR1SummaryData[index].selectBox = flag;
      if (flag === true) {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.CATEGORIES !== category
        );
      }
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.gstR1SummaryData = this.gstR1SummaryData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = this.gstR1SummaryData;
      } else {
        this.gstR1SummaryData = this.gstR1SummaryData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
      }
    },
    gstr1SaveFunction() {
      const prompt = confirm('Are you sure you wan to save? Please Confirm!');
      if (prompt) {
        this.gstr1Save();
      }
    },
    gstr1Save() {
      let gstr1SavePayload = '';
      for (let i of this.selectedRow) {
        if (gstr1SavePayload == '') {
          gstr1SavePayload = i.CATEGORIES;
        } else {
          gstr1SavePayload = gstr1SavePayload + ',' + i.CATEGORIES;
        }
      }
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        le_id: this.searchParams.legalEntity.value,
        gst_no: this.searchParams.gstNumber.value,
        period: this.searchParams.month.value,
        trx_categories: gstr1SavePayload
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/gstr1SummarySave', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.data.msg;
            this.requestId = resp.data.data.request_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.loading = false;
        });
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    },
    categoriesModal(flag) {
      this.showOpenCategoriesModal = flag;
      this.eventBus.$emit(
        'showOpenCategoriesModal',
        this.showOpenCategoriesModal
      );
    },
    openCategoriesModal(item) {
      this.searchFilters = {
        ...this.searchParams,
        category: item.CATEGORIES
      };
      this.showOpenCategoriesModal = true;
      this.eventBus.$emit(
        'showOpenCategoriesModal',
        this.showOpenCategoriesModal
      );
    },
    openDifferenceModal(item) {
      this.searchFilters = {
        ...this.searchParams,
        item: item
      };
      this.showOpenDifferenceModal = true;
    },
    differenceModal(flag) {
      this.showOpenDifferenceModal = flag;
    }
  }
};
