import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';
import gstr1SummaryForm from './gstr1SummaryForm';
import gstr1ReturnSummary from './gstr1ReturnSummary';
import hsnSummary from './hsnSummary';
import b2csSummary from './b2csSummary';
import docSummary from './docSummary';
import exempted from './exempted';
import RunRequest from '../../../../request/submittedRequest/runRequest';

export default {
  name: 'gstR1Summary',
  watch: {
    currentPage: function() {
      this.getGstr1Summary();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstr1Summary();
    },
    editMode: function() {
      this.eventBus.$on('edit', edit => {
        this.editMode = edit;
      });
    }
  },
  components: {
    gstr1SummaryForm,
    gstr1ReturnSummary,
    hsnSummary,
    b2csSummary,
    docSummary,
    exempted,
    RunRequest
  },
  data() {
    return {
      editMode: false,
      currentPage: 1,
      totalRows: null,
      perPage: 20,
      pageOptions: commonHelper.getPageOption(),
      loading: false,
      expandBookHead: false,
      expandGstrHead: false,
      expandDiffHead: false,
      legalEntity: {
        value: null,
        text: null
      },
      gstNumber: {
        value: null,
        text: null
      },
      month: {
        value: null,
        text: null
      },
      showValueSetModal: false,
      parent_value_set_id: null,
      tabIndex: 0,
      searchParams: {},
      summaryMatch: null,
      summaryMatchValue: 'all',
      activeTab: 'GSTR1 Summary',
      requestParams: {},
      showOpenCategoriesModal: false,
      showOpenDifferenceModal: false,
      reqRun: {
        legalEntity: {
          text: null,
          value: null
        },
        gstNumber: {
          value: null,
          text: null
        },
        month: {
          value: null,
          text: null
        }
      },
      showRunRequestModal: false
    };
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    },
    gstNumber: {
      text: {
        required
      }
    },
    month: {
      text: {
        required
      }
    }
  },
  created() {
    this.eventBus.$on('showOpenCategoriesModal', showOpenCategoriesModal => {
      this.showOpenCategoriesModal = showOpenCategoriesModal;
    });
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = !this.editMode;
        }
        if (
          actionName === 'download' &&
          !this.showOpenCategoriesModal &&
          !this.showOpenDifferenceModal
        ) {
          this.loading = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          if (this.activeTab === 'GSTR1 Summary') {
            const downloadpayload = { ...this.requestParams };
            downloadpayload._limit = 1000;
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcDataUpdation/getGstr1Summary',
              'GSTR1 Summary',
              () => (this.loading = false)
            );
          } else if (this.activeTab === 'B2CS Summary') {
            const downloadpayload = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.legalEntity.value,
              gst_no: this.gstNumber.value,
              period: this.month.value
            };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcDataUpdation/b2csHsnSummary',
              'B2CS Summary',
              () => (this.loading = false)
            );
          } else if (this.activeTab === 'HSN Return Summary') {
            const downloadpayload = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.legalEntity.value,
              gst_no: this.gstNumber.value,
              period: this.month.value
            };
            downloadpayload._limit = 1000;
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcDataUpdation/gstrhHsnSummary',
              'HSN Summary',
              () => (this.loading = false)
            );
          } else if (this.activeTab === 'GSTR1 Return Summary') {
            const downloadpayload = {
              _page: this.currentPage - 1,
              _limit: this.perPage,
              le_id: this.legalEntity.value,
              gst_no: this.gstNumber.value,
              period: this.month.value
            };
            downloadpayload._limit = 1000;
            this.downloadExcel.downloadData(
              downloadpayload,
              'itcDataUpdation/gstr1ReturnSummary',
              'GSTR1 Return Summary',
              () => (this.loading = false)
            );
          }
        }
      }
    });
  },
  methods: {
    getGstr1Summary() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.searchParams = {
          legalEntity: this.legalEntity,
          gstNumber: this.gstNumber,
          month: this.month,
          perPage: this.perPage
        };
        this.requestParams = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.legalEntity.value,
          gstn_no: this.gstNumber.value,
          period: this.month.value
        };
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.gstNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.month = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.gstNumber.value) {
        this.gstNumber = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.month.value) {
        this.month = {
          value: null,
          text: null
        };
      }
    },
    clearSearch() {
      this.legalEntity = {
        value: null,
        text: null
      };
      this.gstNumber = {
        value: null,
        text: null
      };
      this.month = {
        value: null,
        text: null
      };
      this.gstR1SummaryData = null;
      this.totalRows = null;
      this.currentPage = 1;
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-success', 'text-light'];
      } else {
        return ['bg-light', 'text-info'];
      }
    },
    selectTab(tab) {
      this.totalRows = null;
      this.activeTab = tab;
      this.$store.commit('itcDataUpdation/setGstr1ActiveTab', tab);
    },
    summary(summaryMatch) {
      if (summaryMatch === '0') {
        this.summaryMatchValue = 'filing';
      } else if (summaryMatch === '1') {
        this.summaryMatchValue = 'all';
      } else if (summaryMatch === '2') {
        this.summaryMatchValue = 'filed';
      } else {
        this.summaryMatchValue = null;
      }
    },
    totalRowsCount(item) {
      this.totalRows = item;
    },
    runRequest() {
      if (this.legalEntity.text && this.gstNumber.text && this.month.text) {
        this.reqRun = {
          legalEntity: {
            text: this.legalEntity.text,
            value: this.legalEntity.value
          },
          gstNumber: {
            text: this.gstNumber.text,
            value: this.gstNumber.value
          },
          month: {
            text: this.month.text,
            value: this.month.value
          }
        };
        this.showRunRequestModal = true;
      } else {
        alert('Please select Parent LOV');
      }
    },
    resetModal() {
      this.showRunRequestModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('edit');
    this.eventBus.$off('showOpenCategoriesModal');
  }
};