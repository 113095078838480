export default {
    name: 'exempted',
    data() {
        return {
            loading: false,
            exemptedData: [
                {
                    description: 'Intra-state supplies to registered person',
                    nil_rated_supplies: null,
                    exempted_supplies: null,
                    not_gst_supplies: null
                },
                {
                    description: 'Intra-state supplies to unregistered person',
                    nil_rated_supplies: null,
                    exempted_supplies: null,
                    not_gst_supplies: null
                },
                {
                    description: 'Inter-state supplies to registered person',
                    nil_rated_supplies: null,
                    exempted_supplies: null,
                    not_gst_supplies: null
                },
                {
                    description: 'Inter-state supplies to unregistered person',
                    nil_rated_supplies: null,
                    exempted_supplies: null,
                    not_gst_supplies: null
                },
            ],
            exemptedFields: [
                {
                    key: 'description',
                    label: 'Description',
                    class: 'text-center'
                },
                {
                    key: 'nil_rated_supplies',
                    label: 'Nil Rated Supplies (₹)',
                    class: 'text-center'
                },
                {
                    key: 'exempted_supplies',
                    label: 'Exempted Supplies',
                    class: 'text-center'
                },
                {
                    key: 'not_gst_supplies',
                    label: 'Non GST Supplies (₹)',
                    class: 'text-center'
                }
            ]
        }
    }
}