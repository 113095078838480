import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import total from './total';

export default {
  name: 'hsnSummary',
  props: ['searchParams'],
  components: {
    total
  },
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.leId = this.searchParams.legalEntity.value;
        this.gstNum = this.searchParams.gstNumber.value;
        this.period = this.searchParams.month.value;
        this.hsnSummary(this.searchParams);
      } else {
        this.userData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.hsnSummary(this.searchParams);
    }
  },
  data() {
    return {
      loading: false,
      loader: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      hsnSummaryData: [],
      hsnSummaryFields: [
        {
          key: 'hsn_code',
          label: 'HSN Code',
          variant: 'warning',
          class: 'col-fix text-center'
        },
        {
          key: 'uqc',
          label: 'UQC(Unit Of Measure)',
          variant: 'warning',
          class: 'col-fix text-center'
        },
        {
          key: 'rate',
          label: 'Rate',
          variant: 'warning',
          class: 'col-fix text-center'
        },
        {
          key: 'item_desc',
          label: 'Item Desc',
          variant: 'warning',
          class: 'col-fix text-center'
        },
        {
          key: 'qty_book',
          label: 'Quantity',
          variant: 'info',
          class: 'col-fix'
        },
        // {
        //   key: 'invoice_amount_book',
        //   label: 'Invoice Amount',
        //   variant: 'info',
        //   class: 'col-fix text-right'
        // },
        {
          key: 'taxable_amount_book',
          label: 'Taxable Amount',
          variant: 'info',
          class: 'col-fix text-right'
        },
        {
          key: 'igst_amt_book',
          label: 'IGST Amount',
          variant: 'info',
          class: 'col-fix text-right'
        },
        {
          key: 'cgst_amt_book',
          label: 'CGST Amount',
          variant: 'info',
          class: 'col-fix text-right'
        },
        {
          key: 'sgst_amt_book',
          label: 'SGST Amount',
          variant: 'info',
          class: 'col-fix text-right'
        },
        {
          key: 'qty_r1',
          label: 'Quantity',
          variant: 'secondary',
          class: 'col-fix'
        },
        // {
        //   key: 'invoice_amount_r1',
        //   label: 'Invoice Amount',
        //   variant: 'secondary',
        //   class: 'col-fix text-right'
        // },
        {
          key: 'taxable_amount_r1',
          label: 'Taxable Amount',
          variant: 'secondary',
          class: 'col-fix text-right'
        },
        {
          key: 'igst_amt_r1',
          label: 'IGST Amount',
          variant: 'secondary',
          class: 'col-fix text-right'
        },
        {
          key: 'cgst_amt_r1',
          label: 'CGST Amount',
          variant: 'secondary',
          class: 'col-fix text-right'
        },
        {
          key: 'sgst_amt_r1',
          label: 'SGST Amount',
          variant: 'secondary',
          class: 'col-fix text-right'
        },
        {
          key: 'qty_diff',
          label: 'Quantity',
          variant: 'danger',
          class: 'col-fix'
        },
        // {
        //   key: 'invoice_amount_diff',
        //   label: 'Invoice Amount',
        //   variant: 'danger',
        //   class: 'col-fix text-right'
        // },
        {
          key: 'taxable_amount_diff',
          label: 'Taxable Amount',
          variant: 'danger',
          class: 'col-fix text-right'
        },
        {
          key: 'igst_amt_diff',
          label: 'IGST Amount',
          variant: 'danger',
          class: 'col-fix text-right'
        },
        {
          key: 'cgst_amt_diff',
          label: 'CGST Amount',
          variant: 'danger',
          class: 'col-fix text-right'
        },
        {
          key: 'sgst_amt_diff',
          label: 'SGST Amount',
          variant: 'danger',
          class: 'col-fix text-right'
        }
      ],
      hsnFilingFlag: false,
      expandCollapseFlag: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      requestId: '',
      showOpenTotalModal: false,
      minimizeFlag: true,
      totalData: [],
      leId: null,
      gstNum: null,
      period: null,
      editMode: null,
      unsubscribe: null
    };
  },
  mounted() {
    if (this.searchParams.legalEntity) {
      this.leId = this.searchParams.legalEntity.value;
      this.gstNum = this.searchParams.gstNumber.value;
      this.period = this.searchParams.month.value;
      this.hsnSummary(this.searchParams);
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'update' &&
          this.$store.state.itcDataUpdation.activeTab === 'HSN Return Summary'
        ) {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    formattedAmount(num) {
      return commonHelper.formatCurrencyAmount(num);
    },
    hsnSummary(params) {
      this.totalData = [];
      this.hsnSummaryData = [];
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        le_id: params.legalEntity.value,
        gst_no: params.gstNumber.value,
        period: params.month.value
      };
      this.loading = true;
      let quantitybooks = 0;
      let taxableamountbook = 0;
      let igstamountbooks = 0;
      let cgstamountbooks = 0;
      let sgstamountbooks = 0;
      let quantityr1 = 0;
      let taxableamountr1 = 0;
      let igstamountr1 = 0;
      let cgstamountr1 = 0;
      let sgstamountr1 = 0;
      let quantitydifference = 0;
      let taxableamountdifference = 0;
      let igstamountdifference = 0;
      let cgstamountdifference = 0;
      let sgstamountdifference = 0;
      this.$store
        .dispatch('itcDataUpdation/gstrhHsnSummary', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.hsnSummaryData = result;
            this.totalRows = resp.data.data.total_elements;
            for (let i of result) {
              quantitybooks = quantitybooks + +i.qty_book;
              taxableamountbook = taxableamountbook + +i.taxable_amount_book;
              igstamountbooks = igstamountbooks + +i.igst_amt_book;
              cgstamountbooks = cgstamountbooks + +i.cgst_amt_book;
              sgstamountbooks = sgstamountbooks + +i.sgst_amt_book;
              quantityr1 = quantityr1 + +i.qty_r1;
              taxableamountr1 = taxableamountr1 + +i.taxable_amount_r1;
              igstamountr1 = igstamountr1 + +i.igst_amt_r1;
              cgstamountr1 = cgstamountr1 + +i.cgst_amt_r1;
              sgstamountr1 = sgstamountr1 + +i.sgst_amt_r1;
              quantitydifference = quantitydifference + +i.qty_diff;
              taxableamountdifference =
                taxableamountdifference + +i.taxable_amount_diff;
              igstamountdifference = igstamountdifference + +i.igst_amt_diff;
              cgstamountdifference = cgstamountdifference + +i.cgst_amt_diff;
              sgstamountdifference = sgstamountdifference + +i.sgst_amt_diff;
            }
            this.totalData.push({
              qty_book: quantitybooks,
              taxable_amount_book: taxableamountbook.toFixed(2),
              igst_amt_book: igstamountbooks.toFixed(2),
              cgst_amt_book: cgstamountbooks.toFixed(2),
              sgst_amt_book: sgstamountbooks.toFixed(2),
              qty_r1: quantityr1,
              taxable_amount_r1: taxableamountr1.toFixed(2),
              igst_amt_r1: igstamountr1.toFixed(2),
              cgst_amt_r1: cgstamountr1.toFixed(2),
              sgst_amt_r1: sgstamountr1.toFixed(2),
              qty_diff: quantitydifference,
              taxable_amount_diff: taxableamountdifference.toFixed(2),
              igst_amt_diff: igstamountdifference.toFixed(2),
              cgst_amt_diff: cgstamountdifference.toFixed(2),
              sgst_amt_diff: sgstamountdifference.toFixed(2)
            });
          } else {
            this.$bvToast.toast(resp.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    hsnSaveFunction() {
      const prompt = confirm('Are you sure you wan to save? Please Confirm!');
      if (prompt) {
        this.hsnSave();
      }
    },
    expandCollapseTable() {
      this.expandCollapseFlag = !this.expandCollapseFlag;
      if (this.expandCollapseFlag) {
        this.hsnSummaryFields.forEach(column => {
          column.class = 'col-fix-large';
        });
      } else {
        this.hsnSummaryFields.forEach(column => {
          column.class = 'col-fix';
        });
      }
    },
    hsnSave() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.searchParams.legalEntity.value,
        gst_no: this.searchParams.gstNumber.value,
        period: this.searchParams.month.value,
        trx_categories: 'HSN'
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/gstr1SummarySave', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.data.msg;
            this.requestId = resp.data.data.request_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.loading = false;
        });
    },
    updateItemDesc() {
      if (this.editMode) {
        const itemDetails = this.hsnSummaryData.map(elem => {
          return {
            item_desc: elem.item_desc,
            hsn_code: elem.hsn_code,
            uqc: elem.uqc,
            rate: elem.rate,
            le_id: this.leId,
            gst_no: this.gstNum,
            period: this.period
          };
        });
        const payload = {
          getparams: itemDetails
        };
        this.loader = true;
        this.$store
          .dispatch('itcDataUpdation/updateItemDescHsnSummary', payload)
          .then(resp => {
            this.loader = false;
            this.editMode = false;
            if (resp.status === 200) {
              this.hsnSummary(this.searchParams);
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.$bvToast.toast(resp.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    totalModal(flag) {
      this.showOpenTotalModal = flag;
    },
    openTotalModal() {
      this.showOpenTotalModal = true;
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
