import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'total',
  props: ['totalData', 'gstr1SummaryTotalData', 'formName'],
  data() {
    return {
      hsnSummaryTotalData: [],
      hsnSummaryTotalFields: [
        // {
        //   key: 'hsn_code',
        //   label: 'HSN Code',
        //   variant: 'warning',
        //   class: 'col-fix-large text-center'
        // },
        // {
        //   key: 'uqc',
        //   label: 'UQC(Unit Of Measure)',
        //   variant: 'warning',
        //   class: 'col-fix-large text-center'
        // },
        // {
        //   key: 'rate',
        //   label: 'Rate',
        //   variant: 'warning',
        //   class: 'col-fix-large text-center'
        // },
        {
          key: 'qty_book',
          label: 'Quantity',
          variant: 'info',
          class: 'col-fix-large text-center'
        },
        {
          key: 'taxable_amount_book',
          label: 'Taxable Amount',
          variant: 'info',
          class: 'col-fix-large text-right'
        },
        {
          key: 'igst_amt_book',
          label: 'IGST Amount',
          variant: 'info',
          class: 'col-fix-large text-right'
        },
        {
          key: 'cgst_amt_book',
          label: 'CGST Amount',
          variant: 'info',
          class: 'col-fix-large text-right'
        },

        {
          key: 'sgst_amt_book',
          label: 'SGST Amount',
          variant: 'info',
          class: 'col-fix-large text-right'
        },
        {
          key: 'qty_r1',
          label: 'Quantity',
          variant: 'secondary',
          class: 'col-fix-large text-center'
        },
        {
          key: 'taxable_amount_r1',
          label: 'Taxable Amount',
          variant: 'secondary',
          class: 'col-fix-large text-right'
        },
        {
          key: 'igst_amt_r1',
          label: 'IGST Amount',
          variant: 'secondary',
          class: 'col-fix-large text-right'
        },
        {
          key: 'cgst_amt_r1',
          label: 'CGST Amount',
          variant: 'secondary',
          class: 'col-fix-large text-right'
        },
        {
          key: 'sgst_amt_r1',
          label: 'SGST Amount',
          variant: 'secondary',
          class: 'col-fix-large text-right'
        },
        {
          key: 'qty_diff',
          label: 'Quantity',
          variant: 'danger',
          class: 'col-fix-large text-center'
        },
        {
          key: 'taxable_amount_diff',
          label: 'Taxable Amount',
          variant: 'danger',
          class: 'col-fix-taxable text-center'
        },
        {
          key: 'igst_amt_diff',
          label: 'IGST Amount',
          variant: 'danger',
          class: 'col-fix-large text-right'
        },
        {
          key: 'cgst_amt_diff',
          label: 'CGST Amount',
          variant: 'danger',
          class: 'col-fix-large text-right'
        },
        {
          key: 'sgst_amt_diff',
          label: 'SGST Amount',
          variant: 'danger',
          class: 'col-fix-large text-right'
        }
      ],
      gstr1SummaryTotalFields: [
        {
          key: 'book_invoice_value',
          label: 'Books Invoice Amount',
          variant: 'success',
          class: 'col-fix-large-inv text-center'
        },
        {
          key: 'book_taxable_value',
          label: 'Books Taxable Amount',
          variant: 'success',
          class: 'col-fix-large-inv text-center'
        },
        {
          key: 'book_tax_amount',
          label: 'Amount',
          variant: 'success',
          class: 'col-fix-large text-center'
        },
        {
          key: 'books_igst',
          label: 'IGST',
          variant: 'success',
          class: 'col-fix-large text-center'
        },
        {
          key: 'books_cgst',
          label: 'CGST',
          variant: 'success',
          class: 'col-fix-large text-center'
        },
        {
          key: 'books_sgst',
          variant: 'success',
          class: 'col-fix-large text-center'
        },
        {
          key: 'gstr1_invoice_value',
          label: 'GSTR1 Invoice Amount',
          variant: 'secondary',
          class: 'col-fix-large-inv text-center'
        },
        {
          key: 'gstr1_taxable_value',
          label: 'GSTR1 Taxable Amount',
          variant: 'secondary',
          class: 'col-fix-large-inv text-center'
        },
        {
          key: 'gstr1_total_tax_amount',
          label: 'Amount',
          variant: 'secondary',
          class: 'col-fix-large text-center'
        },
        {
          key: 'gstr1_igst',
          label: 'IGST',
          variant: 'secondary',
          class: 'col-fix-large text-center'
        },

        {
          key: 'gstr1_cgst',
          label: 'CGST',
          variant: 'secondary',
          class: 'col-fix-large text-center'
        },
        {
          key: 'gstr1_sgst',
          label: 'SGST',
          variant: 'secondary',
          class: 'col-fix-large text-center'
        },
        {
          key: 'diff_invoice_value',
          label: 'Diff Invoice Amount',
          variant: 'danger',
          class: 'col-fix-large-inv text-center'
        },
        {
          key: 'diff_taxable_value',
          label: 'Diff Taxable Amount',
          variant: 'danger',
          class: 'col-fix-large-inv text-center'
        },
        {
          key: 'diff_total_tax_amount',
          label: 'Diff Amount',
          variant: 'danger',
          class: 'col-fix-large text-center'
        },
        {
          key: 'diff_igst',
          label: 'IGST',
          variant: 'danger',
          class: 'col-fix-large text-center'
        },
        {
          key: 'diff_cgst',
          label: 'CGST',
          variant: 'danger',
          class: 'col-fix-large text-center'
        },
        {
          key: 'diff_sgst',
          label: 'SGST',
          variant: 'danger',
          class: 'col-fix-large text-center'
        }
      ],
      gstr1SummaryData: []
    };
  },
  mounted() {
    if (this.formName === 'hsnSummary') {
      this.hsnSummaryTotalData = this.totalData;
    } else if (this.formName === 'gstr1Summary') {
      this.gstr1SummaryData = this.gstr1SummaryTotalData;
    }
  },
  methods: {
    formattedAmount(num) {
      return commonHelper.formatCurrencyAmount(num);
    }
  }
};
