import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'difference',
  props: ['searchFilters'],
  watch: {
    currentPage1: function() {
      this.getDifferenceAll(this.searchFilters);
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getDifferenceAll(this.searchFilters);
    },
    currentPage0: function() {
      this.getDifferenceWithDifference(this.searchFilters);
    },
    perPage0: function() {
      this.currentPage0 = 1;
      this.getDifferenceWithDifference(this.searchFilters);
    },
    currentPage2: function() {
      this.getDifferenceWithoutDifference(this.searchFilters);
    },
    perPage2: function() {
      this.currentPage2 = 1;
      this.getDifferenceAll(this.searchFilters);
      this.getDifferenceWithoutDifference(this.searchFilters);
    }
  },
  validations: {
    legalEntity: {
      text: {
        required
      }
    },
    gstNumber: {
      text: {
        required
      }
    },
    periodName: {
      text: {
        required
      }
    }
  },
  data() {
    return {
      loading: false,
      currentPage0: 1,
      currentPage1: 1,
      currentPage2: 1,
      perPage0: 10,
      perPage1: 10,
      perPage2: 10,
      legalEntity: {
        value: null,
        text: null
      },
      periodName: {
        value: null,
        text: null
      },
      gstNumber: {
        value: null,
        text: null
      },
      gstr1Invoice: null,
      booksInvoice: null,
      sourceCategory: {
        value: null,
        text: null
      },
      docType: {
        value: null,
        text: null
      },
      invoiceType: {
        value: null,
        text: null
      },
      pageOptions: commonHelper.getPageOption(),
      differenceData: [],
      differenceField: [
        {
          key: 'gstn_no',
          label: 'GST Number',
          class: 'text-center'
        },
        {
          key: 'all_categories',
          class: 'text-center'
        },
        {
          key: 'books_source_type',
          class: 'text-center'
        },
        {
          key: 'gstr1_prd',
          label: 'GSTR1 Period',
          class: 'text-center'
        },
        {
          key: 'books_inv_type',
          label: 'Books Invoice Type',
          class: 'text-center'
        },
        {
          key: 'books_prd',
          label: 'Books Period',
          class: 'text-center'
        },
        {
          key: 'gstr1_inv_type',
          label: 'GSTR1 Invoice Type',
          class: 'text-center'
        },
        // {
        //     key: 'GSTR1_DOCS_TYPE',
        //     class: 'text-center'
        // },
        {
          key: 'books_gstn',
          label: 'Books GSTN',
          class: 'text-center'
        },
        {
          key: 'gstr1_invoices',
          label: 'GSTR1 Invoices',
          class: 'text-center'
        },
        {
          key: 'books_inv',
          label: 'Books Invoice',
          class: 'text-center'
        },
        {
          key: 'gstr1_total_tax',
          label: 'GSTR1 Total Amount',
          class: 'text-center'
        },
        {
          key: 'books_total_tax',
          label: 'Books Total Amount',
          class: 'text-center'
        },
        {
          key: 'gstr1_igst_amt',
          label: 'GSTR1 IGST Amount',
          class: 'text-center'
        },
        {
          key: 'books_igst',
          label: 'Books IGST',
          class: 'text-center'
        },
        {
          key: 'gstr1_csgt_amt',
          label: 'GSTR1 CGST Amount',
          class: 'text-center'
        },
        {
          key: 'books_cgst',
          label: 'Books CGST',
          class: 'text-center'
        },
        {
          key: 'gstr1_sgst_amt',
          label: 'GSTR1 SGST Amount',
          class: 'text-center'
        },
        {
          key: 'books_sgst',
          label: 'Books SGST',
          class: 'text-center'
        },
        {
          key: 'gsrtr1_taxable_value',
          label: 'GSTR-1 Taxable Amt',
          class: 'text-center'
        },
        {
          key: 'books_taxable_value',
          label: 'Books Taxable Amt',
          class: 'text-center'
        },
        {
          key: 'diff_taxable_value',
          label: 'Difference Taxable Amt',
          class: 'text-center'
        },
        {
          key: 'gstr1_invoice_value',
          label: 'GSTR-1 Invoice Amt',
          class: 'text-center'
        },
        {
          key: 'books_invoice_value',
          label: 'Books Invoice Amt',
          class: 'text-center'
        },
        {
          key: 'diff_invoice_value',
          label: 'Difference Invoice Amt',
          class: 'text-center'
        },
        {
          key: 'diff_inv_tax',
          label: 'Difference Total Tax Amt',
          class: 'text-center'
        },
        {
          key: 'diff_igst',
          label: 'Difference IGST',
          class: 'text-center'
        },
        {
          key: 'diff_cgst',
          label: 'Difference CGST',
          class: 'text-center'
        },
        {
          key: 'diff_sgst',
          label: 'Difference SGST',
          class: 'text-center'
        }
      ],
      summaryMatch: null,
      difference: '1',
      totalRows: null,
      differenceFlag: '1',
      showValueSetModal: false,
      parent_value_set_id: null,
      defaultValue: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (this.searchFilters) {
      this.getParentToChildData(this.searchFilters);
      this.changeDifference('1');
    }
  },
  methods: {
    formattedAmount(num) {
      // this function formats the amount that is wrapped inside this function into Indian Currency System with rupee symbol
      return commonHelper.formatCurrencyAmount(num);
    },
    mainSearch() {
      if (this.differenceFlag === '0') {
        this.getDifferenceWithDifference();
      } else if (this.differenceFlag === '1') {
        this.getDifferenceAll();
      } else {
        this.getDifferenceWithoutDifference();
      }
    },
    getDifferenceAll() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        le_id: this.legalEntity.value,
        gstn_no: this.gstNumber.value,
        period_name: this.periodName.value,
        gstr1_total_invoices: this.gstr1Invoice,
        book_inv: this.booksInvoice,
        sourcecategory: this.sourceCategory.value,
        doc_type: this.docType.value,
        invoice_type: this.invoiceType.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/getR1SummaryAllInvoices', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.differenceData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDifferenceWithDifference() {
      const payload = {
        _page: this.currentPage0 - 1,
        _limit: this.perPage0,
        le_id: this.legalEntity.value,
        gstn_no: this.gstNumber.value,
        period_name: this.periodName.value,
        gstr1_total_invoices: this.gstr1Invoice,
        book_inv: this.booksInvoice,
        sourcecategory: this.sourceCategory.value,
        doc_type: this.docType.value,
        invoice_type: this.invoiceType.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/getR1SummaryWithDifference', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.differenceData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getDifferenceWithoutDifference() {
      const payload = {
        _page: this.currentPage2 - 1,
        _limit: this.perPage2,
        le_id: this.legalEntity.value,
        gstn_no: this.gstNumber.value,
        period_name: this.periodName.value,
        gstr1_total_invoices: this.gstr1Invoice,
        book_inv: this.booksInvoice,
        sourcecategory: this.sourceCategory.value,
        doc_type: this.docType.value,
        invoice_type: this.invoiceType.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/getR1SummaryWithoutDifference', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.differenceData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    changeDifference(difference) {
      if (difference === '1') {
        this.differenceFlag = '1';
        this.getDifferenceAll();
      } else if (difference === '0') {
        this.differenceFlag = '0';
        this.getDifferenceWithDifference();
      } else {
        this.differenceFlag = '2';
        this.getDifferenceWithoutDifference();
      }
    },
    getParentToChildData(item) {
      this.legalEntity = item.legalEntity;
      this.gstNumber = item.gstNumber;
      this.periodName = item.month;
      // this.gstr1Invoice = item.item.GSTR1_INVOICES;
      this.sourceCategory = {
        value: item.item.CATEGORIES,
        text: item.item.CATEGORIES
      };
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD
      ) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (vsetCode === this.gstNumber.value) {
        this.gstNumber = this.defaultValue;
      } else if (vsetCode === this.periodName.value) {
        this.periodName = this.defaultValue;
      } else if (vsetCode === this.invoiceType.value) {
        this.invoiceType = this.defaultValue;
      } else if (vsetCode === this.docType.value) {
        this.docType = this.defaultValue;
      } else if (vsetCode === this.sourceCategory.value) {
        this.sourceCategory = this.defaultValue;
      } else if (vsetCode === 'gstr1Invoice') {
        this.gstr1Invoice = null;
      } else if (vsetCode === 'booksInvoice') {
        this.booksInvoice = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN
      ) {
        this.gstNumber = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GL_PERIOD) {
        this.periodName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_INVOICE_TYPE) {
        this.invoiceType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.GST_DOCUMENT_TYPE) {
        this.docType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.GSTR1_INVOICE_CATEGORIES
      ) {
        this.sourceCategory = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    clearSearch() {
      this.legalEntity = this.defaultValue;
      this.gstNumber = this.defaultValue;
      this.periodName = this.defaultValue;
      this.invoiceType = this.defaultValue;
      this.docType = this.defaultValue;
      this.sourceCategory = this.defaultValue;
      this.gstr1Invoice = null;
      this.booksInvoice = null;
      this.differenceData = [];
    },
    downloadExcelData() {
      if (this.difference === '0') {
        this.loading = true;
        const downloadpayload = {
          _page: this.currentPage0 - 1,
          _limit: this.perPage0,
          le_id: this.legalEntity.value,
          gstn_no: this.gstNumber.value,
          period_name: this.periodName.value,
          gstr1_total_invoices: this.gstr1Invoice,
          book_inv: this.booksInvoice,
          sourcecategory: this.sourceCategory.value,
          doc_type: this.docType.value,
          invoice_type: this.invoiceType.value
        };
        downloadpayload._limit = this.totalRows;
        this.downloadExcel.downloadData(
          downloadpayload,
          'itcDataUpdation/getR1SummaryWithDifference',
          'GSTR1 Summary With Difference',
          () => (this.loading = false)
        );
      } else if (this.difference === '1') {
        this.loading = true;
        const downloadpayload = {
          _page: this.currentPage1 - 1,
          _limit: this.perPage1,
          le_id: this.legalEntity.value,
          gstn_no: this.gstNumber.value,
          period_name: this.periodName.value,
          gstr1_total_invoices: this.gstr1Invoice,
          book_inv: this.booksInvoice,
          sourcecategory: this.sourceCategory.value,
          doc_type: this.docType.value,
          invoice_type: this.invoiceType.value
        };
        downloadpayload._limit = this.totalRows;
        this.downloadExcel.downloadData(
          downloadpayload,
          'itcDataUpdation/getR1SummaryAllInvoices',
          'GSTR1 Summary All',
          () => (this.loading = false)
        );
      } else if (this.difference === '2') {
        this.loading = true;
        const downloadpayload = {
          _page: this.currentPage2 - 1,
          _limit: this.perPage2,
          le_id: this.legalEntity.value,
          gstn_no: this.gstNumber.value,
          period_name: this.periodName.value,
          gstr1_total_invoices: this.gstr1Invoice,
          book_inv: this.booksInvoice,
          sourcecategory: this.sourceCategory.value,
          doc_type: this.docType.value,
          invoice_type: this.invoiceType.value
        };
        downloadpayload._limit = this.totalRows;
        this.downloadExcel.downloadData(
          downloadpayload,
          'itcDataUpdation/getR1SummaryWithoutDifference',
          'GSTR1 Summary Without Difference',
          () => (this.loading = false)
        );
      }
    }
  }
  // beforeDestroy() {
  //   this.unsubscribe();
  // }
};
