import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';


export default {
  name: 'b2csSummary',
  props: ['searchParams'],
  watch: {
    searchParams() {
      if (this.searchParams) {
        this.b2csSummary(this.searchParams);
      } else {
        this.userData = [];
      }
    },
    totalRows() {
      this.$emit('totalRowsCount', this.totalRows);
    },
    currentPage: function() {
      this.searchParams._page = this.currentPage - 1;
      this.b2csSummary(this.searchParams);
    }
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      b2csSummaryData: [],
      b2csSummaryFields: [
        {
          key: 'pos',
          label: 'Place Of Supply',
          variant: 'warning',
          class: 'col-fix'
        },
        {
          key: 'rate',
          variant: 'warning',
          class: 'col-fix'
        },
        // {
        //   key: 'invoice_amount_book',
        //   label: 'Invoice Amount',
        //   variant: 'info',
        //   class: 'col-fix'
        // },
        {
          key: 'taxable_amount_book',
          label: 'Taxable Amount',
          variant: 'info',
          class: 'col-fix'
        },
        {
          key: 'igst_amt_book',
          label: 'IGST Amount',
          variant: 'info',
          class: 'col-fix'
        },
        {
          key: 'cgst_amt_book',
          label: 'CGST Amount',
          variant: 'info',
          class: 'col-fix'
        },
        {
          key: 'sgst_amt_book',
          label: 'SGST Amount',
          variant: 'info',
          class: 'col-fix'
        },
        // {
        //   key: 'invoice_amount_r1',
        //   label: 'Invoice Amount',
        //   variant: 'secondary',
        //   class: 'col-fix'
        // },
        {
          key: 'taxable_amount_r1',
          label: 'Taxable Amount',
          variant: 'secondary',
          class: 'col-fix'
        },
        {
          key: 'igst_amt_r1',
          label: 'IGST Amount',
          variant: 'secondary',
          class: 'col-fix'
        },
        {
          key: 'cgst_amt_r1',
          label: 'IGST Amount',
          variant: 'secondary',
          class: 'col-fix'
        },
        {
          key: 'sgst_amt_r1',
          label: 'IGST Amount',
          variant: 'secondary',
          class: 'col-fix'
        },
        // {
        //   key: 'invoice_amount_diff',
        //   label: 'Invoice Amount',
        //   variant: 'danger',
        //   class: 'col-fix'
        // },
        {
          key: 'taxable_amount_diff',
          label: 'Taxable Amount',
          variant: 'danger',
          class: 'col-fix'
        },
        {
          key: 'igst_amt_diff',
          label: 'IGST Amount',
          variant: 'danger',
          class: 'col-fix'
        },
        {
          key: 'cgst_amt_diff',
          label: 'CGST Amount',
          variant: 'danger',
          class: 'col-fix'
        },
        {
          key: 'sgst_amt_diff',
          label: 'SGST Amount',
          variant: 'danger',
          class: 'col-fix'
        }
      ],
      expandCollapseFlag: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      requestId: ''
    };
  },
  mounted() {
    if(this.searchParams.legalEntity) {
      this.b2csSummary(this.searchParams);
    }  
  },
  methods: {
    b2csSummary(params) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        le_id: params.legalEntity.value,
        gst_no: params.gstNumber.value,
        period: params.month.value
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/b2csHsnSummary', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.b2csSummaryData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    expandCollapseTable() {
      this.expandCollapseFlag = !this.expandCollapseFlag;
      if (this.expandCollapseFlag) {
        this.b2csSummaryFields.forEach(column => {
          column.class = 'col-fix-large';
        });
      } else {
        this.b2csSummaryFields.forEach(column => {
          column.class = 'col-fix';
        });
      }
    },
    b2csSaveFunction() {
      const prompt = confirm('Are you sure you wan to save? Please Confirm!');
      if (prompt) {
        this.b2csSave();
      }
    },
    b2csSave() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.searchParams.perPage,
        le_id: this.searchParams.legalEntity.value,
        gst_no: this.searchParams.gstNumber.value,
        period: this.searchParams.month.value,
        trx_categories: 'B2CS'
      };
      this.loading = true;
      this.$store
        .dispatch('itcDataUpdation/gstr1SummarySave', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = resp.data.data.msg;
            this.requestId = resp.data.data.request_id;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = resp.response.data.message;
          }
        })
        .catch(() => {
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
          this.loading = false;
        });
    }
  }
};
